import React from 'react';
import { useParams } from 'react-router';

import {
  faCircleCheck,
  faDollarSign,
  faPercent,
  faTriangleExclamation,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Checkbox, CheckboxOnChange, FontIcon, LoadedButton } from 'components';
import {
  ActivityAuditType,
  ApplicationInput,
  LoanProposalQuery,
  RateType,
  useAttachEvaluationPdfMutation,
  useStartApplicationMutation,
} from 'generated/graphql';
import { useQueryMutation } from 'queries/apiFetch/useQueryMutation';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  filterDecimal,
  formatDecimal,
  formatDecimalWithCommas,
  getNumberValue,
  getNumberValueWith2Decimal,
  printMoney,
} from 'utils';
import { v4 as uuidv4 } from 'uuid';

import { useCustomisation } from 'modules/root/Settings';
import { handleViewInformApplication } from 'utils/inform';
import { getLoanRateTypeLabel } from 'utils/labels';

import { InterestRate } from './InterestRate';
import { Spread } from './Spread';
import { DropdownController } from './controllers/DropdownController';
import { TextboxController } from './controllers/TextboxController';
import { buildLoanProposalResolver } from './formResolver';
import { LoanProposalFormValues } from './types';

interface LoanProposalFormProps {
  loanProposal: LoanProposalQuery;
}

export const LoanProposalForm: React.FC<LoanProposalFormProps> = ({ loanProposal }) => {
  const { customerId = '', evaluationId = '' } = useParams();
  const { t } = useTranslation();
  const { labels } = useCustomisation();

  const {
    applicationConfig: {
      loanProposal: {
        fieldsOptions: {
          loanPurpose: loanPurposeConfig,
          primaryLoanPurpose: primaryLoanPurposeConfig,
          productType: productTypeConfig,
          loanType: loanTypeConfig,
          loanTerm: loanTermConfig,
          borrowerType: borrowerTypeConfig,
          loanIndex: loanIndexConfig,
          exceptionRequest: exceptionRequestConfig,
          interestRateDefaultValue,
        },
        rates: ratesConfig,
        showFields,
        applicationSubmittedMsg,
      },
    },
  } = loanProposal;

  const interestRateTypeOptions = [
    getLoanRateTypeLabel(RateType.Fixed),
    getLoanRateTypeLabel(RateType.Variable),
  ];

  const loanProposalResolver = buildLoanProposalResolver(loanProposal.applicationConfig);
  const { handleSubmit, control, setValue, formState, trigger, resetField, ...methods } =
    useForm<LoanProposalFormValues>({
      defaultValues: {
        loanType: loanTypeConfig.defaultValue ?? '',
        productType: productTypeConfig?.defaultValue ?? '',
        interestRateType: interestRateDefaultValue ?? '',
        borrowerType: borrowerTypeConfig.defaultValue ?? '',
        exceptionRequestDropdown: exceptionRequestConfig.defaultValue ?? '',
        loanPurpose: loanPurposeConfig?.defaultValue ?? '',
        proposedPricing: loanIndexConfig.defaultValue ?? '',
      },
      resolver: loanProposalResolver,
      mode: 'onBlur',
    });

  const [formBundleID, setFormBundleID] = React.useState<null | string>(null);
  const [formSubmitError, setFormSubmitError] = React.useState(false);
  const [pdfAttachError, setPdfAttachError] = React.useState(false);

  const loanAmount = useWatch({ control, name: 'loanAmount' });
  const loanPurpose = useWatch({ control, name: 'loanPurpose' });
  const proposedPricing = useWatch({ control, name: 'proposedPricing' });
  const interestRateType = useWatch({ control, name: 'interestRateType' });
  const loanType = useWatch({ control, name: 'loanType' });

  const canShowInterestRate: boolean = !!(
    ratesConfig &&
    loanType &&
    loanAmount &&
    interestRateType &&
    (interestRateType.toUpperCase() === RateType.Fixed || proposedPricing)
  );
  const { mutateAsync } = useQueryMutation(useStartApplicationMutation, {
    extra: {
      auditReport: {
        activityType: ActivityAuditType.Write,
        customerExternalId: loanProposal?.evaluation?.customerExternalId || '',
      },
    },
  });
  const { mutateAsync: mutateAsyncPdf } = useQueryMutation(
    useAttachEvaluationPdfMutation,
    {
      extra: {
        auditReport: {
          activityType: ActivityAuditType.Write,
          customerExternalId: loanProposal?.evaluation?.customerExternalId || '',
        },
      },
    }
  );

  const maxBorrowingPowerFormatted = formatDecimalWithCommas(
    loanProposal?.evaluation?.commitmentAmount?.toString() || ''
  );

  const onSubmit = async (data: LoanProposalFormValues) => {
    const applicationFormData: ApplicationInput = {
      id: uuidv4(),
      loanPurpose: data.loanPurpose,
      primaryLoanPurpose: data.primaryLoanPurpose,
      productType: data.productType,
      loanType: data.loanType,
      loanTerm: data.loanTerm || '',
      interestRateType: data.interestRateType,
      proposedPricing: data.proposedPricing,
      borrowerType: data.borrowerType,
      floor: getNumberValueWith2Decimal(data.floor),
      fees: getNumberValueWith2Decimal(data.fees),
      spread: getNumberValue(data.spread),
      initialRate: getNumberValueWith2Decimal(data.initialRate),
      exceptionRequest: data.exceptionRequestDropdown,
      exceptionRequestRationale: data.exceptionRequestTextbox,
      requestedLoanAmount: getNumberValueWith2Decimal(data.loanAmount),
      customerId: customerId || '',
      evaluationId: evaluationId,
      maxBorrowingPowerChecked: maxBorrowingPowerChecked,
    };
    try {
      const responseData = await mutateAsync({
        application: applicationFormData,
      });
      setFormBundleID(responseData.saveApplication.formBundleId);
      setFormSubmitError(false);
    } catch (e) {
      setFormBundleID(null);
      setFormSubmitError(true);
    }
  };

  React.useEffect(() => {
    if (formBundleID) {
      (async () => {
        try {
          await mutateAsyncPdf({ formBundleId: formBundleID });
          setPdfAttachError(false);
        } catch (e) {
          setPdfAttachError(true);
        }
      })();
    }
  }, [formBundleID, mutateAsyncPdf]);

  const [maxBorrowingPowerChecked, setMaxBorrowingPowerChecked] = React.useState(false);
  const [maxLoanAmountWarning, setMaxLoanAmountWarning] = React.useState(false);
  const [minLoanAmountWarning, setMinLoanAmountWarning] = React.useState(false);

  const handleMaximumAmount: CheckboxOnChange = ({ checked }) => {
    if (checked) {
      setValue('loanAmount', maxBorrowingPowerFormatted, { shouldValidate: true });
      setMaxLoanAmountWarning(false);
      setMinLoanAmountWarning(false);
    }
    setMaxBorrowingPowerChecked(checked);
  };

  React.useEffect(() => {
    setValue('loanAmount', maxBorrowingPowerFormatted, { shouldValidate: true });
  }, [maxBorrowingPowerFormatted, setValue]);

  React.useEffect(() => {
    if (interestRateType?.toUpperCase() === RateType.Fixed) {
      setValue('proposedPricing', '');
    }
  }, [interestRateType, setValue]);

  React.useEffect(() => {
    resetField('loanTerm');
  }, [loanType, resetField]);

  React.useEffect(() => {
    if (loanPurpose !== t('loanProposal.other')) {
      setValue('otherLoanPurpose', '');
    }
  }, [loanPurpose, t, setValue]);

  const validateMaxLoanAmount = (): void => {
    const maxBorrowingPowerValue = getNumberValue(maxBorrowingPowerFormatted);
    const loanAmountValue = getNumberValue(loanAmount);
    maxBorrowingPowerValue && loanAmountValue && maxBorrowingPowerValue < loanAmountValue
      ? setMaxLoanAmountWarning(true)
      : setMaxLoanAmountWarning(false);
  };

  const validateMinLoanAmount = () => {
    const loanAmountValue = getNumberValue(loanAmount);
    const minimumLoanAmount =
      loanProposal.applicationConfig.loanProposal.minimumLoanAmount || 0;
    const minimumLoanAmountWarning =
      loanProposal.applicationConfig.loanProposal.minimumLoanAmountWarning || 0;
    if (
      loanAmountValue < minimumLoanAmountWarning &&
      loanAmountValue >= minimumLoanAmount
    ) {
      setMinLoanAmountWarning(true);
    } else {
      setMinLoanAmountWarning(false);
    }
  };

  const handleLoanAmountBlur = (_event: React.FocusEvent<Element>) => {
    validateMaxLoanAmount();
    validateMinLoanAmount();
    trigger('loanAmount');
  };

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      resetField={resetField}
      trigger={trigger}
      setValue={setValue}
      control={control}
      formState={formState}
      {...methods}
    >
      <form
        className="w-[500px]"
        data-testid="loan-proposal-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextboxController
          label={labels.loanAmount}
          name={'loanAmount'}
          data-testid={'loan-amount'}
          hidden={!showFields.loanAmount}
          formatFn={formatDecimalWithCommas}
          filterFn={filterDecimal}
          disabled={maxBorrowingPowerChecked}
          prefixIcon={faDollarSign}
          onBlur={handleLoanAmountBlur}
          control={control}
        />
        {showFields.loanAmount && (
          <div className="pt-2 mb-4">
            <Checkbox
              data-testid="check-maximum-amount"
              label={`Maximum Borrowing Power ($${maxBorrowingPowerFormatted})`}
              name={'maxAmount'}
              value={'maximum amount'}
              checked={false}
              onChange={handleMaximumAmount}
            />
          </div>
        )}
        {maxLoanAmountWarning && (
          <div className="warning my-4">
            <FontIcon className="mr-2" icon={faTriangleExclamation} />
            {t('loanProposal.maxLoanAmoutWarning')}
          </div>
        )}
        {minLoanAmountWarning && (
          <div className="warning my-4">
            <FontIcon className="mr-2" icon={faTriangleExclamation} />
            {t('loanProposal.minLoanAmoutWarning', {
              minLoanAmountWarning: printMoney(
                loanProposal.applicationConfig.loanProposal.minimumLoanAmountWarning
              ),
            })}
          </div>
        )}
        <DropdownController
          label={labels.loanPurpose}
          options={loanPurposeConfig.options}
          hidden={!showFields.loanPurpose}
          showOther={loanPurposeConfig.other}
          name={'loanPurpose'}
          data-testid={'loan-purpose'}
          control={control}
        />
        <TextboxController
          label={labels.otherLoanPurpose}
          name={'otherLoanPurpose'}
          data-testid={'other-loan-purpose'}
          hidden={loanPurpose !== t('common.other')}
          control={control}
        />
        <DropdownController
          label={labels.primaryLoanPurpose}
          options={primaryLoanPurposeConfig?.options || []}
          hidden={!showFields.primaryLoanPurpose}
          name={'primaryLoanPurpose'}
          data-testid={'primary-loan-purpose'}
          control={control}
        />
        <DropdownController
          label={labels.loanProposalType}
          options={loanTypeConfig.options}
          hidden={!showFields.loanType}
          name={'loanType'}
          data-testid={'loan-type'}
          control={control}
        />
        <DropdownController
          label={labels.productType}
          options={productTypeConfig?.options || []}
          hidden={!showFields.productType}
          name={'productType'}
          data-testid={'product-type'}
          control={control}
        />
        <DropdownController
          label={labels.loanTerm}
          options={loanTermConfig.options}
          hidden={!showFields.loanTerm}
          name={'loanTerm'}
          data-testid={'loan-term'}
          control={control}
        />
        <DropdownController
          label={labels.interestRateType}
          options={interestRateTypeOptions}
          hidden={!showFields.interestRateType}
          name={'interestRateType'}
          data-testid={'interest-rate-type'}
          control={control}
        />
        <DropdownController
          label={labels.proposedPricing}
          options={loanIndexConfig.variable || []}
          name={'proposedPricing'}
          hidden={
            !showFields.proposedPricing ||
            interestRateType !== getLoanRateTypeLabel(RateType.Variable)
          }
          data-testid={'proposed-pricing'}
          control={control}
        />
        <Spread ratesConfig={ratesConfig} showField={showFields.spread}></Spread>
        <TextboxController
          label={labels.floor}
          name={'floor'}
          hidden={!showFields.floor}
          data-testid={'floor'}
          suffixIcon={faPercent}
          formatFn={formatDecimal}
          filterFn={filterDecimal}
          control={control}
        />
        <TextboxController
          label={labels.fees}
          name={'fees'}
          hidden={!showFields.fees}
          data-testid={'fees'}
          suffixIcon={faPercent}
          formatFn={formatDecimal}
          filterFn={filterDecimal}
          control={control}
        />
        <TextboxController
          label={labels.initialRate}
          name={'initialRate'}
          hidden={!showFields.initialRate}
          data-testid={'initial-rate'}
          suffixIcon={faPercent}
          formatFn={formatDecimal}
          filterFn={filterDecimal}
          control={control}
        />
        {canShowInterestRate && <InterestRate ratesConfig={ratesConfig} />}
        <DropdownController
          label={labels.borrowerType}
          options={borrowerTypeConfig.options}
          hidden={!showFields.borrowerType}
          name={'borrowerType'}
          data-testid={'borrower-type'}
          control={control}
        />
        <DropdownController
          label={labels.exceptionRequest}
          options={exceptionRequestConfig.options}
          hidden={!showFields.exceptionRequest}
          name={'exceptionRequestDropdown'}
          data-testid={'exception-request-dropdown'}
          control={control}
        />
        <TextboxController
          label={labels.exceptionRequest}
          name={'exceptionRequestTextbox'}
          hidden={!showFields.exceptionRequestRationale}
          data-testid={'exception-request'}
          placeholder={labels.exceptionRequestPlaceholder}
          multiline={true}
          control={control}
        />
        <div className="flex justify-end items-center mr-4">
          <LoadedButton
            isLoading={formState.isSubmitting}
            type="submit"
            state="primary"
            disabled={!formState.isValid || formState.isSubmitting || !!formBundleID}
            data-testid="start-application-btn"
          >
            {labels.startApplication}
          </LoadedButton>
        </div>

        {formSubmitError && (
          <div className="alert my-4 mr-5 relative">
            <FontIcon
              className="cursor-pointer absolute top-3 right-3"
              onClick={() => setFormSubmitError(false)}
              icon={faXmark}
            />
            <FontIcon className="mr-2" size="lg" icon={faTriangleExclamation} />
            {t('loanProposal.applicationNotCreatedError')}
          </div>
        )}

        {formBundleID && (
          <div className="success my-4 mr-5 relative text-left">
            <FontIcon
              className="cursor-pointer absolute top-3 right-3"
              onClick={() => setFormBundleID(null)}
              icon={faXmark}
            />
            <div className="whitespace-pre-line">
              <FontIcon className="mr-2 text-green-500" size="lg" icon={faCircleCheck} />
              {applicationSubmittedMsg}
            </div>
            <div className="mt-2 text-right">
              <Button
                state="primary"
                className="ml-2"
                size="sm"
                onClick={() => handleViewInformApplication(formBundleID)}
              >
                {t('loanProposal.goToApplication')}
              </Button>
            </div>
          </div>
        )}

        {pdfAttachError && (
          <div className="warning my-4 mr-5 relative">
            <FontIcon
              className="cursor-pointer absolute top-3 right-3"
              onClick={() => setPdfAttachError(false)}
              icon={faXmark}
            />
            <FontIcon className="mr-2" size="lg" icon={faTriangleExclamation} />
            {t('loanProposal.pdfUploadFailedError')}
          </div>
        )}
      </form>
    </FormProvider>
  );
};
