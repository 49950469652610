import { HelpIcon } from 'components';
import { CompareTo, RateType, SingleLoanQuery } from 'generated/graphql';
import { t } from 'i18next';

import { StatusBadge } from 'modules/common';
import { CustomisationConfig } from 'modules/root/Settings';
import { getCompareToLabel, getLoanRateTypeLabel, getStatusLabel } from 'utils/labels';
import {
  printBoolean,
  printDate,
  printDateWithoutTimezone,
  printForStatus,
  printMoney,
  printPercent,
  printPrimitive,
} from 'utils/print';

import { AccountLinks } from './AccountLinks/AccountLinks';

type Value = JSX.Element | string | string[];
type Label = JSX.Element | string;

export type LoanField = {
  key?: string;
  label: Label;
  value: Value;
};

type Field = LoanField & {
  key: string;
  show: boolean;
  showDash: boolean;
};

export class PlainLoanFieldsFormatter {
  // This class should be used and extended to get plain string type fields.
  // e.g. for PDF sending of loan data

  loan: SingleLoanQuery['loan'];
  customer: SingleLoanQuery['customer'];
  config: CustomisationConfig;
  keyRequired: boolean = false;

  constructor(
    loan: SingleLoanQuery['loan'],
    customer: SingleLoanQuery['customer'],
    config: CustomisationConfig
  ) {
    this.loan = loan;
    this.customer = customer;
    this.config = config;
  }

  getDetails() {
    return this.getFields([
      {
        key: 'status',
        label: this.config.labels.loanStatus,
        value: this.status,
        show: this.config.loanDetailsInfo.showFields.loanStatus,
        showDash: false,
      },
      {
        key: 'obligor',
        label: this.config.labels.loanObligor,
        value: this.obligor,
        show: this.config.loanDetailsInfo.showFields.loanObligor,
        showDash: true,
      },
      {
        key: 'obligation',
        label: this.config.labels.loanObligation,
        value: this.obligation,
        show: this.config.loanDetailsInfo.showFields.loanObligation,
        showDash: true,
      },
      {
        key: 'product-type',
        label: this.config.labels.loanType,
        value: this.productType,
        show: this.config.loanDetailsInfo.showFields.loanType,
        showDash: false,
      },
      {
        key: 'start-date',
        label: this.config.labels.startDate,
        value: this.startDate,
        show: this.config.loanDetailsInfo.showFields.startDate,
        showDash: true,
      },
      {
        key: 'end-date',
        label: this.labelEndDate,
        value: this.endDate,
        show: this.config.loanDetailsInfo.showFields.endDate,
        showDash: true,
      },
      {
        key: 'auto-renewable',
        label: this.config.labels.autoRenewable,
        value: this.autoRenewable,
        show: this.config.loanDetailsInfo.showFields.autoRenewable,
        showDash: true,
      },
    ]);
  }

  getKeyInformation() {
    return this.getFields([
      {
        key: 'utilization',
        label: this.config.labels.utilization,
        value: this.utilization,
        show: this.config.loanKeyInformation.showFields.utilization,
        showDash: true,
      },
      {
        key: 'availability',
        label: this.config.labels.availability,
        value: this.availability,
        show: this.config.loanKeyInformation.showFields.availability,
        showDash: true,
      },
      {
        key: 'commitment-amount',
        label: this.labelCommitmentAmount,
        value: this.commitmentAmount,
        show: this.config.loanKeyInformation.showFields.commitmentAmount,
        showDash: true,
      },
      {
        key: 'outstanding-balance',
        label: this.config.labels.outstandingBalance,
        value: this.outstandingBalance,
        show: this.config.loanKeyInformation.showFields.outstandingBalance,
        showDash: true,
      },
      {
        key: 'compare-to',
        label: this.config.labels.compareTo,
        value: this.compareTo,
        show: this.config.loanKeyInformation.showFields.compareTo,
        showDash: true,
      },
      {
        key: 'minimum-balance',
        label: this.config.labels.minimumBalance,
        value: this.minimumBalance,
        show:
          this.config.loanKeyInformation.showFields.minimumBalance &&
          this.loan?.compareTo === CompareTo.MinimumBalance,
        showDash: true,
      },
    ]);
  }

  getInterestRates() {
    return this.getFields([
      {
        key: 'current-interest-rate',
        label: this.config.labels.currentInterestRate,
        value: this.currentInterestRate,
        show: this.config.loanInterestRates.showFields.currentInterestRate,
        showDash: true,
      },
      {
        key: 'rate-type',
        label: this.config.labels.rateType,
        value: this.rateType,
        show: this.config.loanInterestRates.showFields.rateType,
        showDash: true,
      },
      {
        key: 'rate-index',
        label: this.config.labels.rateIndex,
        value: this.rateIndex,
        show:
          this.config.loanInterestRates.showFields.rateIndex &&
          this.loan?.rateType === RateType.Variable,
        showDash: true,
      },
      {
        key: 'spread',
        label: this.labelSpread,
        value: this.spread,
        show:
          this.config.loanInterestRates.showFields.spread &&
          this.loan?.rateType === RateType.Variable,
        showDash: true,
      },
      {
        key: 'floor-rate',
        label: this.config.labels.floorRate,
        value: this.floorRate,
        show:
          this.config.loanInterestRates.showFields.floorRate &&
          this.loan?.rateType === RateType.Variable,
        showDash: true,
      },
    ]);
  }

  getInterestPayments() {
    return this.getFields([
      {
        key: 'payment-due-date',
        label: this.labelPaymentDueDate,
        value: this.paymentDueDate,
        show: this.config.loanInterestPayments.showFields.paymentDueDate,
        showDash: true,
      },
      {
        key: 'payment-amount',
        label: this.labelPaymentAmount,
        value: this.paymentAmount,
        show: this.config.loanInterestPayments.showFields.paymentAmount,
        showDash: true,
      },
      {
        key: 'account-number',
        label: this.config.labels.accountNumber,
        value: this.accountNumber,
        show: this.config.loanInterestPayments.showFields.accountNumber,
        showDash: true,
      },
    ]);
  }

  getInterestHistory() {
    return this.getFields([
      {
        key: 'interest-since-inception',
        label: this.config.labels.interestSinceInception,
        value: this.interestSinceInception,
        show: this.config.loanInterestHistory.showFields.interestSinceInception,
        showDash: true,
      },
      {
        key: 'daily-interest',
        label: this.config.labels.dailyInterest,
        value: this.dailyInterest,
        show: this.config.loanInterestHistory.showFields.dailyInterest,
        showDash: true,
      },
      {
        key: 'mtd-interest',
        label: this.config.labels.mtdInterest,
        value: this.mtdInterest,
        show: this.config.loanInterestHistory.showFields.mtdInterest,
        showDash: true,
      },
      {
        key: 'prior-year-interest',
        label: this.config.labels.priorYearInterest,
        value: this.priorYearInterest,
        show: this.config.loanInterestHistory.showFields.priorYearInterest,
        showDash: true,
      },
      {
        key: 'ytd-interest-paid',
        label: this.config.labels.ytdInterestPaid,
        value: this.ytdInterestPaid,
        show: this.config.loanInterestHistory.showFields.ytdInterestPaid,
        showDash: true,
      },
    ]);
  }

  getPledgedAccounts() {
    return this.getFields([
      {
        key: 'market-value',
        label: this.config.labels.marketValue,
        value: this.marketValue,
        show: this.config.loanPledgedAccounts.showFields.marketValue,
        showDash: true,
      },
      {
        key: 'eligible-market-value',
        label: this.config.labels.eligibleMarketValue,
        value: this.eligibleMarketValue,
        show: this.config.loanPledgedAccounts.showFields.eligibleMarketValue,
        showDash: true,
      },
      {
        key: 'collateral-value',
        label: this.labelCollateralValue,
        value: this.collateralValue,
        show: this.config.loanPledgedAccounts.showFields.collateralValue,
        showDash: true,
      },
      {
        key: 'remaining-market-value',
        label: this.config.labels.remainingMarketValue,
        value: this.remainingMarketValue,
        show: Boolean(
          this.config.loanPledgedAccounts.showFields.remainingMarketValue &&
            this.loan?.isCrossCollateralised
        ),
        showDash: true,
      },
      {
        key: 'remaining-eligible-market-value',
        label: this.config.labels.remainingEligibleMarketValue,
        value: this.remainingEligibleMarketValue,
        show: Boolean(
          this.config.loanPledgedAccounts.showFields.remainingEligibleMarketValue &&
            this.loan?.isCrossCollateralised
        ),
        showDash: true,
      },
      {
        key: 'remaining-collateral-value',
        label: this.config.labels.remainingCollateralValue,
        value: this.remainingCollateralValue,
        show: Boolean(
          this.config.loanPledgedAccounts.showFields.remainingCollateralValue &&
            this.loan?.isCrossCollateralised
        ),
        showDash: true,
      },
      {
        key: 'loan-collateral',
        label: this.config.labels.pledgedAccounts,
        value: this.loanCollateral,
        show: this.config.loanPledgedAccounts.showFields.pledgedAccounts,
        showDash: true,
      },
    ]);
  }

  getOpportunity() {
    return this.getFields([
      {
        key: 'lendable-amount',
        label: this.config.labels.lendableAmount,
        value: this.lendableAmount,
        show:
          this.config.loanOpportunity.showFields.lendableAmount &&
          !this.loan?.isCrossCollateralised,
        showDash: true,
      },
      {
        key: 'potential-to-borrow',
        label: this.config.labels.potentialToBorrow,
        value: this.potentialToBorrow,
        show:
          this.config.loanOpportunity.showFields.potentialToBorrow &&
          !this.loan?.isCrossCollateralised,
        showDash: true,
      },
    ]);
  }

  getBlendedRates() {
    return this.getFields([
      {
        key: 'blended-advance-rate',
        label: this.labelBlendedAdvanceRate,
        value: this.blendedAdvanceRate,
        show: this.config.loanBlendedRates.showFields.blendedAdvanceRate,
        showDash: true,
      },
      {
        key: 'blended-top-up-rate',
        label: this.labelBlendedTopUpRate,
        value: this.blendedTopUpRate,
        show: this.config.loanBlendedRates.showFields.blendedTopUpRate,
        showDash: true,
      },
      {
        key: 'blended-sell-out-rate',
        label: this.labelBlendedSellOutRate,
        value: this.blendedSellOutRate,
        show: this.config.loanBlendedRates.showFields.blendedSellOutRate,
        showDash: true,
      },
    ]);
  }

  get status(): Value {
    return getStatusLabel(this.loan?.status);
  }

  get obligor(): Value {
    return printPrimitive(this.loan?.obligor);
  }

  get obligation(): Value {
    return printPrimitive(this.loan?.obligation);
  }

  get productType(): Value {
    return printPrimitive(this.loan?.productType);
  }

  get startDate(): Value {
    return printDateWithoutTimezone(this.loan?.startDate);
  }

  get endDate(): Value {
    return printDateWithoutTimezone(this.loan?.endDate);
  }

  get autoRenewable(): Value {
    return printBoolean(this.loan?.autoRenewable);
  }

  get utilization(): Value {
    return printPercent(this.loan?.utilizationRate);
  }

  get availability(): Value {
    return printMoney(this.loan?.availability);
  }

  get commitmentAmount(): Value {
    return printMoney(this.loan?.commitmentAmount);
  }

  get outstandingBalance(): Value {
    return printMoney(this.loan?.outstandingBalance);
  }

  get compareTo(): Value {
    return getCompareToLabel(this.loan?.compareTo);
  }

  get minimumBalance(): Value {
    return printMoney(this.loan?.minimumBalance);
  }

  get currentInterestRate(): Value {
    return printPercent(this.loan?.currentInterestRate);
  }

  get rateType(): Value {
    return getLoanRateTypeLabel(this.loan?.rateType);
  }

  get rateIndex(): Value {
    return printPrimitive(this.loan?.rateIndex);
  }

  get spread(): Value {
    return printPercent(this.loan?.spread);
  }

  get floorRate(): Value {
    return printPercent(this.loan?.floorRate);
  }

  get paymentDueDate(): Value {
    return this.loan?.nextPaymentDueDate
      ? printDate(this.loan?.nextPaymentDueDate)
      : t('interestRates.pendingInvoice');
  }

  get paymentAmount(): Value {
    return this.loan?.paymentAmount
      ? printMoney(this.loan?.paymentAmount)
      : t('interestRates.pendingInvoice');
  }

  get accountNumber(): Value {
    const accountNumber = this.loan?.accountNumber
      ? `${t('customer.loans.accountEnding')}-${this.loan.accountNumber.substring(
          this.loan.accountNumber.length - 4
        )}`
      : '';
    return printPrimitive(accountNumber);
  }

  get interestSinceInception(): Value {
    return printMoney(this.loan?.interestSinceInception);
  }

  get dailyInterest(): Value {
    return printMoney(this.loan?.dailyInterest);
  }

  get mtdInterest(): Value {
    return printMoney(this.loan?.mtdInterest);
  }

  get priorYearInterest(): Value {
    return printMoney(this.loan?.priorYearInterest);
  }

  get ytdInterestPaid(): Value {
    return printMoney(this.loan?.ytdInterestPaid);
  }

  get marketValue(): Value {
    return printMoney(this.loan?.marketValue);
  }

  get eligibleMarketValue(): Value {
    return printMoney(this.loan?.eligibleMarketValue);
  }

  get collateralValue(): Value {
    return printMoney(this.loan?.collateralValue);
  }

  get remainingMarketValue(): Value {
    return printMoney(this.loan?.remainingMarketValue);
  }

  get remainingEligibleMarketValue(): Value {
    return printMoney(this.loan?.remainingEligibleMarketValue);
  }

  get remainingCollateralValue(): Value {
    return printMoney(this.loan?.remainingCollateralValue);
  }

  get loanCollateral(): Value {
    return (
      this.loan?.loanCollateral?.reduce<string[]>((colls, loanColl) => {
        if (loanColl?.accountId) {
          colls.push(loanColl.accountId);
        }
        return colls;
      }, []) || []
    );
  }

  get lendableAmount(): Value {
    return printMoney(this.customer?.opportunity?.lendableAmount);
  }

  get potentialToBorrow(): Value {
    return printMoney(this.customer?.opportunity?.potentialToBorrow);
  }

  get blendedAdvanceRate(): Value {
    return printPercent(this.loan?.blendedAdvanceRate);
  }

  get blendedTopUpRate(): Value {
    return printPercent(this.loan?.blendedTopUpRate);
  }

  get blendedSellOutRate(): Value {
    return printPercent(this.loan?.blendedSellOutRate);
  }

  get labelEndDate(): Label {
    return this.config.labels.endDate;
  }

  get labelCommitmentAmount(): Label {
    return this.config.labels.commitmentAmount;
  }

  get labelPaymentDueDate(): Label {
    return this.config.labels.paymentDueDate;
  }

  get labelPaymentAmount(): Label {
    return this.config.labels.paymentAmount;
  }

  get labelCollateralValue(): Label {
    return this.config.labels.collateralValue;
  }

  get labelBlendedAdvanceRate(): Label {
    return this.config.labels.blendedAdvanceRate;
  }

  get labelBlendedTopUpRate(): Label {
    return this.config.labels.blendedTopUpRate;
  }

  get labelBlendedSellOutRate(): Label {
    return this.config.labels.blendedSellOutRate;
  }

  get labelSpread(): Label {
    return this.config.labels.spread;
  }

  private getFields = (fields: Field[]) => {
    return fields.reduce<LoanField[]>((fields, field) => {
      if (field.show) {
        if (this.keyRequired) {
          fields.push({
            key: field.key,
            label: field.label,
            value: printForStatus(field.value, field.showDash, this.loan?.status),
          });
        } else {
          fields.push({
            label: field.label,
            value: printForStatus(field.value, field.showDash, this.loan?.status),
          });
        }
      }
      return fields;
    }, []);
  };
}

export class LoanFieldsFormatterJSX extends PlainLoanFieldsFormatter {
  // This class can be used and extended to get JSX fields
  // e.g. for Loan screen components

  keyRequired: boolean = true;

  get status() {
    return this.loan?.status ? (
      <StatusBadge status={this.loan?.status} />
    ) : (
      printPrimitive(this.loan?.status)
    );
  }

  get loanCollateral() {
    return (
      <AccountLinks
        collateralAccounts={this.customer.collateralAccounts}
        loanCollateral={this.loan?.loanCollateral}
      />
    );
  }

  get labelEndDate() {
    return (
      <div className="flex flex-nowrap items-center gap-1">
        {this.config.labels.endDate} <HelpIcon text={this.config.helpText.endDate} />
      </div>
    );
  }

  get labelCommitmentAmount() {
    return (
      <div className="flex flex-nowrap items-center gap-1">
        {this.config.labels.commitmentAmount}
        <HelpIcon text={this.config.helpText.commitmentAmount} />
      </div>
    );
  }

  get labelPaymentDueDate() {
    return (
      <div className="flex flex-nowrap items-center gap-1">
        {this.config.labels.paymentDueDate}
        <HelpIcon
          text={
            this.loan?.nextPaymentDueDate
              ? this.config.helpText.paymentDueDate
              : this.config.helpText.noInvoice
          }
        />
      </div>
    );
  }

  get labelPaymentAmount() {
    return (
      <div className="flex flex-nowrap items-center gap-1">
        {this.config.labels.paymentAmount}
        <HelpIcon
          text={
            this.loan?.paymentAmount
              ? this.config.helpText.paymentAmount
              : this.config.helpText.noInvoice
          }
        />
      </div>
    );
  }

  get labelCollateralValue() {
    return (
      <div className="flex flex-nowrap items-center gap-1">
        {this.config.labels.collateralValue}
        <HelpIcon text={this.config.helpText.collateralValue} />
      </div>
    );
  }

  get labelBlendedAdvanceRate() {
    return (
      <div className="flex flex-nowrap items-center gap-1">
        {this.config.labels.blendedAdvanceRate}
        <HelpIcon text={this.config.helpText.blendedAdvanceRate} />
      </div>
    );
  }

  get labelBlendedTopUpRate() {
    return (
      <div className="flex flex-nowrap items-center gap-1">
        {this.config.labels.blendedTopUpRate}
        <HelpIcon text={this.config.helpText.blendedTopUpRate} />
      </div>
    );
  }

  get labelBlendedSellOutRate() {
    return (
      <div className="flex flex-nowrap items-center gap-1">
        {this.config.labels.blendedSellOutRate}
        <HelpIcon text={this.config.helpText.blendedSellOutRate} />
      </div>
    );
  }

  get labelSpread() {
    return (
      <div className="flex flex-nowrap items-center gap-1">
        {this.config.labels.spread}
        <HelpIcon text={this.config.helpText.spread} />
      </div>
    );
  }
}
