import React from 'react';
import { generatePath, useNavigate } from 'react-router';

import { Button, Ellipsis } from 'components';
import { ActivityAuditType, useAuditReportMutation } from 'generated/graphql';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { graphqlClient, useAuthClient } from 'queries/apiFetch/graphqlClient';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed } from 'services/auditReport';

import { useCustomisation, usePaths } from 'modules/root/Settings';

import { useInputState } from '../Evaluation/useInputState';
import { isCustomerEligibleAtom, selCustomerAtom } from '../models/customer';
import {
  EvaluationAction,
  EvaluationState,
  evaluationActionAtom,
  evaluationStateAtom,
  payloadAtom,
  payloadSnapshotAtom,
  saveDownloadStateAtom,
} from '../models/evaluation';
import { currentStepAtom, nextStepAtom } from '../models/step';

interface ControlPanelProps {}

export const ControlPanel: React.FC<ControlPanelProps> = (props) => {
  const { t } = useTranslation();
  const [nextStep, setNextStep] = useAtom(nextStepAtom);
  const currentStep = useAtomValue(currentStepAtom);
  const [evalState, setEvalState] = useAtom(evaluationStateAtom);
  const saveDownloadState = useAtomValue(saveDownloadStateAtom);
  const customerExternalId = useAtomValue(selCustomerAtom)?.customerId;
  const customerId = useAtomValue(selCustomerAtom)?.id;
  const evalAction = useAtomValue(evaluationActionAtom);
  const setPayloadSnapshot = useSetAtom(payloadSnapshotAtom);
  const payload = useAtomValue(payloadAtom);
  const isCustomerEligible = useAtomValue(isCustomerEligibleAtom);
  const { isValid, isDirty } = useInputState();
  const { labels } = useCustomisation();

  const navigate = useNavigate();
  const paths = usePaths();
  const authClient = useAuthClient(graphqlClient);
  const { mutateAsync } = useAuditReportMutation(authClient);

  const handleFirstEvaluate = () => {
    setPayloadSnapshot(payload);
    setNextStep();
  };
  const handleEvaluate = () => {
    setPayloadSnapshot(payload);
    setEvalState(EvaluationState.Processing);
  };

  const handleApplyForLoan = () => {
    mutateAsync({
      activityType: ActivityAuditType.Read,
      customerExternalId,
      resourceAccessed: ResourceAccessed.LoanProposal,
      queryDetails: JSON.stringify({
        externalId: saveDownloadState?.externalEvalId,
      }),
    });
    navigate(
      generatePath(paths.evaluations.startapplication, {
        customerId: customerId,
        evaluationId: saveDownloadState?.evaluationId,
      })
    );
  };

  if (currentStep) {
    // This is the last step before processing the first evaluation
    return (
      <div className="w-[556px] h-full mx-auto flex flex-row justify-end items-center">
        {nextStep ? (
          <Button
            disabled={!nextStep?.enable || !currentStep?.valid}
            onClick={setNextStep}
          >
            {t('common.continue')}
          </Button>
        ) : (
          <Button disabled={!currentStep?.valid} onClick={handleFirstEvaluate}>
            {t('evaluationV2.evaluate')}
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className="w-full h-full flex max-w-[1436px]">
      <div className="h-full w-[50%] max-w-[50%] border-r border-r-gray-200 flex items-center px-10 gap-4">
        <div className="overflow-hidden">
          {evalAction === EvaluationAction.CreateNew && (
            <Ellipsis>{labels.evaluateInfo}</Ellipsis>
          )}
          {[EvaluationAction.View, EvaluationAction.ReEvaluate].includes(evalAction) && (
            <Ellipsis>{labels.reEvaluateInfo}</Ellipsis>
          )}
        </div>
        <div className="grow"></div>
        <Button
          className="whitespace-nowrap"
          disabled={
            !isValid ||
            evalState === EvaluationState.Processing ||
            (!isDirty &&
              evalState !== EvaluationState.RunFailed &&
              evalAction === EvaluationAction.CreateNew) ||
            !isCustomerEligible
          }
          data-testid="evaluate-btn"
          onClick={handleEvaluate}
        >
          {evalAction === EvaluationAction.CreateNew
            ? t('evaluationV2.evaluate')
            : t('evaluationV2.reEvaluate')}
        </Button>
      </div>
      <div className="h-full w-[50%] max-w-[50%] flex items-center px-10 gap-4">
        <div className="overflow-hidden ">
          <Ellipsis>{labels.applyForLoanInfo}</Ellipsis>
        </div>
        <div className="grow"></div>
        <Button
          className="whitespace-nowrap"
          data-testid="apply-for-loan-button"
          disabled={
            !saveDownloadState ||
            !saveDownloadState?.enableApplyForLoan ||
            !saveDownloadState.externalEvalId ||
            !saveDownloadState.evaluationId
          }
          onClick={handleApplyForLoan}
        >
          {labels.applyForALoan}
        </Button>
      </div>
    </div>
  );
};
