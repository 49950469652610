import React from 'react';

import clsx from 'clsx';
import { Card, HighchartsGraph } from 'components';
import { useTranslation } from 'react-i18next';

import { useCustomisation } from 'modules/root/Settings';
import { money } from 'utils/currency';
import { useScreenWidth } from 'utils/helpers';

import { LegendItem } from './LegendItem';
import { getLoanMarginGraphOptions } from './graphOptions';

type Props = {
  eligible: number;
  sellOut: number;
  topUp: number;
  topUpCushion: number | null;
  fixAmount: number | null;
  repaymentFixAmount: number | null;
  className?: string;
};

// TODO need to get below fields from config
// MOCK config - TODO: need to get config from main configuration once available
type MarginAnalysisFieldName =
  | 'eligibleMarketValue'
  | 'sellOutThreshold'
  | 'topUpCushion'
  | 'topUpThreshold';

type MarginAnalysisFieldType = {
  readonly name: MarginAnalysisFieldName;
  readonly label: string;
};

const LoanMarginGraph = React.forwardRef<HTMLDivElement, Props>(
  (
    { eligible, sellOut, topUp, topUpCushion, fixAmount, repaymentFixAmount, className },
    ref
  ) => {
    const { t } = useTranslation();
    const screenwidth = useScreenWidth();
    const options = React.useMemo(() => {
      return getLoanMarginGraphOptions({ eligible, sellOut, topUp, topUpCushion });
      // screenwidth added to recalculate graph width on screen resize
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eligible, screenwidth, sellOut, topUp, topUpCushion]);
    const config = useCustomisation();
    const fields: MarginAnalysisFieldType[] = [
      { name: 'eligibleMarketValue', label: config.labels.eligibleMarketValue },
      { name: 'sellOutThreshold', label: config.labels.sellOutThreshold },
      { name: 'topUpThreshold', label: config.labels.topUpThreshold },
      { name: 'topUpCushion', label: config.labels.topUpCushion },
    ];

    const getLegendLabel = (fieldName: string): string => {
      const legendLabel = fields.find((field) => field.name === fieldName)?.label;
      return legendLabel ? legendLabel : '';
    };

    return (
      <Card title={config.labels.marginAnalysis} ref={ref} className={className}>
        <div data-testid="loan-margin-analysis-graph" className="flex flex-col">
          {topUp >= eligible && fixAmount && repaymentFixAmount ? (
            <div
              data-testid="loan-margin-warning"
              className={clsx('self-center', 'mt-5', 'mx-5', {
                alert: sellOut >= eligible,
                warning: topUp >= eligible && sellOut < eligible,
              })}
            >
              <div className={clsx('text-xs', 'uppercase', 'font-bold', 'text-center')}>
                {`${t('loanMarginGraph.loanMarginWarning.prefix')} = ${money.format(
                  fixAmount
                )}`}
                <br />
                {t('common.or')}
                <br />
                {`${t('loanMarginGraph.loanMarginWarning.suffix')} = ${money.format(
                  repaymentFixAmount
                )}`}
              </div>
            </div>
          ) : null}
          <div className={'p-5 border-b'}>
            <HighchartsGraph options={options} />
          </div>
          <div className={'p-5'}>
            {config.loanMarginAnalysis.showFields.eligibleMarketValue && (
              <LegendItem
                label={getLegendLabel('eligibleMarketValue')}
                bgColor={'bg-primary-600'}
                value={eligible}
              ></LegendItem>
            )}
            {config.loanMarginAnalysis.showFields.sellOutThreshold && (
              <LegendItem
                label={getLegendLabel('sellOutThreshold')}
                bgColor={'bg-red-600'}
                value={sellOut}
              ></LegendItem>
            )}
            {config.loanMarginAnalysis.showFields.topUpThreshold && (
              <LegendItem
                label={getLegendLabel('topUpThreshold')}
                bgColor={'bg-yellow-300'}
                value={topUp}
              ></LegendItem>
            )}
            {topUpCushion && config.loanMarginAnalysis.showFields.topUpCushion && (
              <LegendItem
                data-testid="graph-legend-topupcushion"
                label={getLegendLabel('topUpCushion')}
                bgColor={'bg-primary-400'}
                value={topUpCushion}
              ></LegendItem>
            )}
          </div>
        </div>
      </Card>
    );
  }
);

export { LoanMarginGraph };
