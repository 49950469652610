import React from 'react';

const DEFAULT_PAGE_TITLE = 'NAVIGATOR';
interface NavigationConfig {
  customers: string;
  originations: string;
  reports: string;
  home: string;
}

export const buildPaths = (config: NavigationConfig) => {
  const initial = '/';
  const home = `${initial}${config.home}`;
  const auth = `${initial}auth`;
  const authSignOut = `${auth}/sign-out`;
  const authSignIn = `${auth}/sign-in`;
  const customerList = `${initial}${config.customers}`;
  const customer = `${initial}${config.customers}/:customerId`;
  const loanDetails = `${customer}/loan/:loanId`;
  const customerDetails = `${customer}/details`;
  const customerContacts = `${customer}/contacts`;
  const customerAccounts = `${customer}/accounts`;
  const customerHousehold = `${customer}/household`;
  const reports = `${initial}${config.reports}`;
  const originations = `${initial}${config.originations}`;
  const originationsEvaluations = `${originations}/evaluations`;
  const originationsApplications = `${originations}/applications`;
  const createEvaluation = `${initial}evaluation/:customerId`;
  const viewEvaluation = `${createEvaluation}/:evaluationId`;
  const newEvaluation = `${initial}evaluationV2/new`;
  const proceedNewEvaluation = `${initial}evaluationV2/:customerId`;
  const viewEvaluationV2 = `${proceedNewEvaluation}/:evaluationId`;
  const reEvaluation = `${initial}evaluation/:customerId/:evaluationId/reevaluate`;
  const startApplication = `${initial}evaluation/:customerId/:evaluationId/start-application`;
  const themeReview = `${initial}/theme-review`;

  return {
    initial,
    themeReview,
    home,
    auth: {
      signIn: authSignIn,
      signOut: authSignOut,
    },
    customer: {
      main: customer,
      list: customerList,
      loans: {
        main: customer,
        details: loanDetails,
      },
      details: customerDetails,
      contacts: customerContacts,
      accounts: customerAccounts,
      household: customerHousehold,
    },
    reports,
    evaluations: {
      create: createEvaluation,
      view: viewEvaluation,
      reevaluate: reEvaluation,
      startapplication: startApplication,
    },
    evaluationsV2: {
      new: newEvaluation,
      proceedNew: proceedNewEvaluation,
      view: viewEvaluationV2,
    },
    origination: {
      list: originations,
      evaluations: originationsEvaluations,
      applications: originationsApplications,
    },
  };
};

export type NavigationPaths = ReturnType<typeof buildPaths>;

export const NavigationContext = React.createContext<NavigationPaths>(
  {} as NavigationPaths
);

export const usePageTitle = (title?: string | string[]) => {
  if (title) {
    setTimeout(() => {
      if (Array.isArray(title)) {
        document.title = `${title.filter(Boolean).join(' - ')} - ${DEFAULT_PAGE_TITLE}`;
      } else {
        document.title = `${title} - ${DEFAULT_PAGE_TITLE}`;
      }
    }, 0);
  } else {
    document.title = DEFAULT_PAGE_TITLE;
  }
};

export const usePaths = (): NavigationPaths => React.useContext(NavigationContext);
