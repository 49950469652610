import React, { useState } from 'react';

import { Button, Card } from 'components';
import {
  PartiesSection,
  PartiesSectionType,
  SingleLoanQuery,
  usePartiesQuery,
} from 'generated/graphql';
import { isEmpty as _isEmpty } from 'lodash';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';

import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation } from 'modules/root/Settings';

import { PartiesModal } from './PartiesModal/PartiesModal';
import { PartiesListCustomer } from './PartiesModal/PartiesModalBody';
import { PartyItem } from './PartyItem';
import { partiesTransform } from './partiesUtils';

export interface PartiesState {
  [PartiesSectionType.Borrower]?: PartiesListCustomer;
  [PartiesSectionType.Guarantors]: PartiesListCustomer[];
  [PartiesSectionType.CoBorrowers]: PartiesListCustomer[];
  [PartiesSectionType.AccountOwnerDetails]: PartiesListCustomer[];
}

interface PartiesProps {
  loan: NonNullable<SingleLoanQuery['loan']>;
}

export const Parties: React.FC<PartiesProps> = ({ loan }) => {
  let [isOpen, setIsOpen] = useState(false);
  const {
    partiesV2: { sections },
    labels: { loanParties, seeMore },
  } = useCustomisation();

  const sectionsToShow: PartiesSection[] = React.useMemo(
    () => sections.flatMap((section) => (section && section.show ? section : [])),
    [sections]
  );

  const { data, error, isLoading } = useQueryFetch(usePartiesQuery, {
    queryHookParams: { loanID: loan.id },
  });

  const parties = React.useMemo(
    () => partiesTransform(data?.loanParties || []),
    [data?.loanParties]
  );

  const isEmpty =
    _isEmpty(parties?.borrower) &&
    _isEmpty(parties?.coBorrowers) &&
    _isEmpty(parties?.guarantors) &&
    _isEmpty(parties?.accountOwnerDetails);

  return (
    <>
      {parties && (
        <PartiesModal
          loan={loan}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          parties={parties}
          sections={sectionsToShow}
          title={loanParties}
        />
      )}
      <Card
        testid="loan-parties"
        title={loanParties}
        footer={
          !isEmpty ? (
            <Button
              className="my-4 mx-5 h-9"
              state="secondary"
              onClick={() => setIsOpen(true)}
            >
              {seeMore}
            </Button>
          ) : (
            <></>
          )
        }
      >
        <QuerySuspense error={error} isLoading={isLoading} noData={isEmpty}>
          <PartyItem sections={sectionsToShow} parties={parties}></PartyItem>
        </QuerySuspense>
      </Card>
    </>
  );
};
