import React from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';

import { HelpIcon } from 'components';
import { LoanSummaryQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { StatusBadge } from 'modules/common';
import { CustomisationConfig, usePaths } from 'modules/root/Settings';
import { ArrayElement } from 'utils/genericType';
import { EMPTY_DASH, printForStatus, printMoney } from 'utils/print';

import { AccountLinks } from '../../AccountLinks/AccountLinks';

export const useLoanAccountsFields = (
  config: CustomisationConfig,
  loan: ArrayElement<LoanSummaryQuery['customer']['loans']>,
  collateralAccounts: LoanSummaryQuery['customer']['collateralAccounts']
) => {
  const { customerId } = useParams();
  const paths = usePaths();
  const { t } = useTranslation();

  return React.useMemo(
    () =>
      [
        {
          key: 'loan-number',
          show: config.loanAccounts.showFields.loanNumber,
          label: config.labels.loanNumber,
          showDash: false,
          value: (
            <div className="truncate max-w-[200px]">
              <Link
                className="underline"
                to={generatePath(paths.customer.loans.details, {
                  customerId,
                  loanId: loan?.id,
                })}
              >
                {`${loan?.obligor}-${loan?.obligation}`}
              </Link>
            </div>
          ),
        },
        {
          key: 'loan-status',
          show: config.loanAccounts.showFields.loanStatus,
          label: config.labels.loanStatus,
          showDash: false,
          value: loan?.status ? <StatusBadge status={loan?.status} /> : EMPTY_DASH,
        },
        {
          key: 'commitment-amount',
          show: config.loanAccounts.showFields.commitmentAmount,
          label: (
            <div className="flex flex-nowrap items-center gap-1">
              {config.labels.commitmentAmount}{' '}
              <HelpIcon text={config.helpText.commitmentAmount} />
            </div>
          ),
          showDash: true,
          value: printMoney(loan?.commitmentAmount),
        },
        {
          key: 'outstanding-balance',
          show: config.loanAccounts.showFields.outstandingBalance,
          label: config.labels.outstandingBalance,
          showDash: true,
          value: printMoney(loan?.outstandingBalance),
        },
        {
          key: 'pledged-accounts',
          show: config.loanAccounts.showFields.pledgedAccounts,
          label: config.labels.pledgedAccounts,
          showDash: false,
          value: loan?.loanCollateral ? (
            <AccountLinks
              collateralAccounts={collateralAccounts}
              loanCollateral={loan.loanCollateral}
            />
          ) : (
            EMPTY_DASH
          ),
        },
        {
          key: 'cross-collaterization',
          show: loan?.isCrossCollateralised,
          label: '',
          value: (
            <div className="font-semibold text-red-500 w-[320px] mt-5">
              {t('customer.loans.accounts.crossCollateral')}
            </div>
          ),
        },
      ]
        .filter((field) => field.show)
        .map((field) => {
          field.value = printForStatus(field.value, field.showDash, loan?.status);
          return field;
        }),
    [config, loan, customerId, paths.customer.loans.details, collateralAccounts, t]
  );
};
