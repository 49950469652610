import React from 'react';

import clsx from 'clsx';
import {
  AlertsSummaryQuery,
  DataBarrierFunctionality,
  LoanStatus,
  useAlertsSummaryQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { ArrayElement } from 'utils';

import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';

type AlertsSummary = Omit<
  NonNullable<ArrayElement<AlertsSummaryQuery['alertsSummary']>>,
  '__typename'
>;

export const AlertsToLoanRatio: React.FC<unknown> = () => {
  const { data, isLoading, error } = useQueryFetch(useAlertsSummaryQuery, {
    queryHookParams: { dataBarrierFunctionality: DataBarrierFunctionality.Home },
  });
  const { t } = useTranslation();

  let alertsSummary = data?.alertsSummary as AlertsSummary[];
  const total = alertsSummary?.reduce((acc, curr) => acc + (curr?.count || 0), 0);
  const { permissions } = useAccess();
  return permissions.canViewCollateralAccounts ? (
    <QuerySuspense isLoading={isLoading} error={error} noData={!data?.alertsSummary}>
      <div className="font-semibold mb-6">
        {t('dashboard.alerts.toLoanRatio.total') + ': '}
        {total}
      </div>
      <figure>
        {!!total && <AlertsGraph alerts={alertsSummary} total={total} className="mb-6" />}
        <AlertsLegend alerts={alertsSummary} />
      </figure>
    </QuerySuspense>
  ) : (
    <div className="warning m-2" data-testid="no-permissions-message">
      {t('auth.disallow')}
    </div>
  );
};

const COLORS_MAP: Omit<Record<LoanStatus, string>, 'CLOSED'> = {
  [LoanStatus.SellOut]: 'bg-red-500',
  [LoanStatus.TopUp]: 'bg-orange-300',
  [LoanStatus.DataIssue]: 'bg-red-200',
  [LoanStatus.Watch]: 'bg-yellow-300',
  [LoanStatus.OverAdvanced]: 'bg-primary-300',
  [LoanStatus.PaidOff]: 'bg-purple-300',
  [LoanStatus.Active]: 'bg-cyan-500',
  [LoanStatus.Prospect]: 'bg-primary-300',
};

const AlertsGraph: React.FC<{
  alerts: AlertsSummary[];
  total: number;
  className: string;
}> = ({ alerts, total, className }) => {
  return (
    <div
      className={clsx('flex rounded overflow-hidden h-10', className)}
      data-testid="alerts-to-loan-graph"
    >
      {alerts.map((a) => (
        <div
          key={a.status}
          className={COLORS_MAP[a.status as keyof typeof COLORS_MAP]}
          style={{ flexBasis: `${(a.count / total) * 100}%` }}
        />
      ))}
    </div>
  );
};

const AlertsLegend: React.FC<{ alerts: AlertsSummary[] }> = ({ alerts }) => {
  const { t } = useTranslation();
  return (
    <figcaption>
      {alerts.map(
        (a) =>
          a.count > 0 && (
            <LegendItem
              key={a.status}
              color={COLORS_MAP[a.status as keyof typeof COLORS_MAP]}
              label={t('loanStatus.' + a.status)}
              value={a.count}
            />
          )
      )}
    </figcaption>
  );
};

interface LegendItemProps {
  color: string;
  label: string;
  value: number;
}

const LegendItem: React.FC<LegendItemProps> = ({ color, label, value }) => {
  return (
    <div className="mb-3 last:mb-0 leading-6 flex items-center">
      <div className={clsx('rounded-full h-[10px] w-[10px] mr-3', color)} />
      {label + ':'}
      <span className="font-semibold ml-1">{value}</span>
    </div>
  );
};
