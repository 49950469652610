import React from 'react';
import { useNavigate } from 'react-router';
import { generatePath } from 'react-router-dom';

import { BreadCrumbItemType, Card, Content } from 'components';
import {
  AccountSource,
  ActivityAuditType,
  EvaluationCollateralAccountPayload,
  EvaluationInput,
  RunEvaluationInput,
  useUnpledgedAccountsQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EvaluationHeader } from 'modules/common/EvaluationHeader/EvaluationHeader';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePaths } from 'modules/root/Settings';
import { useBlockNavigation } from 'utils/blockNavigation';
import { notEmpty } from 'utils/helpers';

import { useEvaluationContext } from './Evaluation';
import { LeaveEvaluationModal } from './modals/LeaveEvaluationModal';
import { AccountTable } from './sections/AccountTable';
import { AcquiredAccountsModal } from './sections/AcquiredAccountsModal/AcquiredAccountsModal';
import { EvaluationInfo } from './sections/EvaluationInfo';
import { NewEvaluationSummary } from './sections/Summary/NewEvaluationSummary';
import { saveEvaluationResolver } from './sections/Summary/formResolvers';
import { Account, ValidatedUnpledgedAccounts } from './types';

export enum UIEvaluationStatus {
  Idle = 'IDLE',
  Processing = 'PROCESSING',
  RunFailed = 'RUN_FAILED',
  Ran = 'RAN',
  Saved = 'SAVED',
  SaveFailed = 'SAVE_FAILED',
  Existing = 'EXISTING',
}

export enum EvaluationActions {
  New = 'NEW',
  View = 'VIEW',
  ReEvaluate = 'REEVALUATE',
}

export const NewEvaluation: React.FC = () => {
  const {
    accountsNotify,
    accountList: { data, error, isLoading },
  } = useEvaluationContext();
  const customer = data ? data.customer : null;

  const [evaluationStatus, setEvaluationStatus] = React.useState<UIEvaluationStatus>(
    UIEvaluationStatus.Idle
  );
  const navigate = useNavigate();
  const [showLeaveModal, setShowLeaveModal] = React.useState(false);
  const [runEvaluationPayload, setRunEvaluationPayload] =
    React.useState<RunEvaluationInput>();
  const handleBlock = React.useCallback(() => setShowLeaveModal(true), []);
  const isWorkCanBeLost = [
    UIEvaluationStatus.Processing,
    UIEvaluationStatus.Ran,
    UIEvaluationStatus.SaveFailed,
  ].includes(evaluationStatus);
  const { unblock } = useBlockNavigation(isWorkCanBeLost, handleBlock);
  const { t } = useTranslation();
  const paths = usePaths();

  const { evaluation } = useCustomisation();
  const form = useForm<EvaluationInput>({
    defaultValues: {
      id: '',
      label: '',
      compareTo: evaluation.compareTo,
      evaluationCollateralAccounts: [],
      lastEvaluated: '',
      description: '',
      creditPolicyId: '',
    },
    resolver: saveEvaluationResolver,
    mode: 'onBlur',
  });

  React.useEffect(() => {
    if (customer) {
      form.setValue('customerId', customer.id);
      form.setValue('customerName', customer.displayName);
    }
  }, [customer, form]);

  const runEvaluation = (
    accounts: EvaluationCollateralAccountPayload[],
    creditPolicyId: string
  ) => {
    form.setValue('creditPolicyId', creditPolicyId);
    setRunEvaluationPayload({
      label: '',
      persist: false,
      creditPolicyId: creditPolicyId,
      evaluationCollateralAccounts: accounts,
    });
    setEvaluationStatus(UIEvaluationStatus.Processing);
  };

  const isEvaluationRAN = ![
    UIEvaluationStatus.Idle,
    UIEvaluationStatus.Processing,
    UIEvaluationStatus.RunFailed,
  ].includes(evaluationStatus);

  const handleLeaveEvaluationModalClose = (choose: 'cancel' | 'leave') => {
    if (choose === 'leave') {
      unblock().then((blockedPath) => {
        navigate(blockedPath);
      });
    } else {
      setShowLeaveModal(false);
    }
  };

  const { labels, sideMenu } = useCustomisation();

  const breadcrumbList: BreadCrumbItemType[] = [];

  if (sideMenu.home.show) {
    breadcrumbList.push({ label: labels.home, link: generatePath(paths.home) });
  }
  if (sideMenu.originations.show) {
    breadcrumbList.push({
      label: labels.originations,
      link: generatePath(paths.origination.list),
    });
  }
  breadcrumbList.push({
    label: t('evaluations.newEvaluation'),
    link: generatePath(paths.evaluations.create, {
      customerId: data?.customer.id || '',
    }),
  });

  const systemAccountIds: string[] = React.useMemo(() => {
    return customer?.collateralAccounts
      ? customer?.collateralAccounts
          .map((acc) => acc?.collateralAccountId)
          .filter(notEmpty)
      : [];
  }, [customer]);

  const {
    data: unpledgedAccountsData,
    refetch: fetchUnpledgedAccounts,
    isLoading: isLoadingUnpledgedAccounts,
  } = useQueryFetch(useUnpledgedAccountsQuery, {
    queryHookOptions: {
      enabled: false,
      onError: () => {
        accountsNotify({
          type: 'error',
          messageI18n: 'common.error.unspecific',
          dataTestId: 'unpledged-accounts-error',
        });
      },
    },
    queryHookParams: {
      customerId: customer?.id || '',
      systemAccountIds,
    },
    extra: {
      auditReport: {
        activityType: ActivityAuditType.Read,
        customerExternalId: customer?.customerId,
      },
    },
  });

  let [unpledgedAccounts, setUnpledgedAccounts] =
    React.useState<ValidatedUnpledgedAccounts>([]);
  let [showAcquiredAccountsModal, setShowAcquiredAccountsModal] = React.useState(false);

  const allAccounts: Account[] = React.useMemo(() => {
    const validAccounts = unpledgedAccounts
      .filter((record) => record.errors?.length === 0)
      .map((record) => record.account)
      .filter(notEmpty);
    const collateralAccountsWithSource: Account[] =
      customer?.collateralAccounts
        ?.filter(notEmpty)
        .filter((acc) => acc && acc.source === AccountSource.System)
        .map((acc) => ({ ...acc, ownerDisplayName: customer?.displayName })) || [];
    const unpledgedAccountsWithSource: Account[] =
      validAccounts.map(
        (acc) =>
          acc && {
            ...acc,
            assetHoldings: acc.assetHoldings ? acc.assetHoldings.filter(notEmpty) : [],
          }
      ) || [];
    return collateralAccountsWithSource.concat(unpledgedAccountsWithSource);
  }, [customer, unpledgedAccounts]);

  const handleClose = React.useCallback(() => {
    setShowAcquiredAccountsModal(false);
    setUnpledgedAccounts([]);
  }, []);

  const handleAdd = React.useCallback(() => {
    setShowAcquiredAccountsModal(false);
    unpledgedAccountsData?.unpledgedAccounts &&
      setUnpledgedAccounts(unpledgedAccountsData?.unpledgedAccounts.records);
  }, [unpledgedAccountsData?.unpledgedAccounts]);

  const acquireAccounts = React.useCallback(() => {
    fetchUnpledgedAccounts();
    setShowAcquiredAccountsModal(true);
  }, [fetchUnpledgedAccounts]);

  return (
    <FormProvider {...form}>
      <form>
        <Content header={<EvaluationHeader list={breadcrumbList} />}>
          <div className="my-6 lg:my-10 mx-8 lg:mx-12 xl:mx-20">
            {isEvaluationRAN && (
              <EvaluationInfo readOnly={evaluationStatus === UIEvaluationStatus.Saved} />
            )}
            <QuerySuspense
              error={error}
              isLoading={isLoading || isLoadingUnpledgedAccounts}
            >
              {customer && (
                <Card className="p-6">
                  <>
                    <AccountTable
                      collateralAccounts={allAccounts}
                      runEvaluation={runEvaluation}
                      evaluationStatus={evaluationStatus}
                      evaluationAction={EvaluationActions.New}
                      acquireUnpledgedAccounts={acquireAccounts}
                    />
                  </>
                </Card>
              )}
            </QuerySuspense>
            {runEvaluationPayload && evaluationStatus !== UIEvaluationStatus.Idle && (
              <Card className="my-5 p-6">
                <NewEvaluationSummary
                  status={evaluationStatus}
                  updateEvaluationStatus={setEvaluationStatus}
                  evaluationAction={EvaluationActions.New}
                  runEvaluationPayload={runEvaluationPayload}
                  customerExternalId={customer?.customerId}
                />
              </Card>
            )}
            {showAcquiredAccountsModal && unpledgedAccountsData?.unpledgedAccounts && (
              <AcquiredAccountsModal
                accounts={unpledgedAccountsData?.unpledgedAccounts.records}
                warning={unpledgedAccountsData?.unpledgedAccounts.warning}
                onClose={handleClose}
                onAdd={handleAdd}
              />
            )}
          </div>
        </Content>
      </form>
      {showLeaveModal && (
        <LeaveEvaluationModal onClose={handleLeaveEvaluationModalClose} />
      )}
    </FormProvider>
  );
};
