import React from 'react';

import { useIdleTimer } from 'react-idle-timer';

import { IDLE_TIMEOUT_SECS } from 'modules/root/Settings/envVars';

import { useAuth } from '../auth.context';

const DEFAULT_TIMEOUT_MS = 60 * 10 * 1000;
const STORAGE_NAME = 'nav_idle_time';
const ACTION_THROTTLE_MS = 5000;

export const LogoutOnIdle: React.FC = React.memo(() => {
  const { logout, isAuthenticated } = useAuth();
  let reset: Function | null = null;
  const timeout = IDLE_TIMEOUT_SECS ? IDLE_TIMEOUT_SECS * 1000 : DEFAULT_TIMEOUT_MS;
  const handleOnIdle = (): void => {
    const until = parseInt(localStorage.getItem(STORAGE_NAME) || '0', 10);
    if (until <= new Date().getTime() && isAuthenticated) {
      logout();
    } else if (reset) {
      reset();
    }
  };

  const handleAction = (): void => {
    localStorage.setItem(STORAGE_NAME, (new Date().getTime() + timeout).toString());
  };

  ({ reset } = useIdleTimer({
    throttle: ACTION_THROTTLE_MS,
    timeout,
    onIdle: handleOnIdle,
    onAction: handleAction,
  }));

  return <></>;
});
