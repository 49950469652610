import { FC } from 'react';

import { AccountSelector } from 'modules/EvaluationV2/EvaluationStep/steps/AccountSelector/AccountSelector';
import { CreditPolicyDropdown } from 'modules/EvaluationV2/EvaluationStep/steps/CreditPolicyDropdown/CreditPolicyDropdown';
import { useCustomisation } from 'modules/root/Settings';

interface IReviewProps {}

export const Review: FC<IReviewProps> = (props) => {
  const {
    labels,
    creditPolicy: { allowCreditPolicySelection },
  } = useCustomisation();

  return (
    <div className="w-full flex flex-col p-10 h-[calc(100vh-72px)] overflow-y-auto border-r border-r-gray-200 sticky top-0">
      <div className="flex flex-col w-full">
        <div className="font-bold text-lg pb-5">{labels.reviewYourSelection}</div>
        <div className="font-bold text-lg mb-4">{labels.accounts}</div>
        <AccountSelector withoutHeader={true} className="mb-8" />
        {allowCreditPolicySelection && (
          <>
            <div className="font-bold text-lg mb-4">{labels.creditPolicy}</div>
            <div className="xl:mb-8 mb-2">
              <CreditPolicyDropdown />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
