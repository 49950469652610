import React from 'react';
import { generatePath } from 'react-router';

import { ButtonLink } from 'components';
import {
  ActivityAuditType,
  CustomerEvaluationsQuery,
  useCustomerEvaluationsQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed, useSendAuditReport } from 'services/auditReport';
import { ArrayElement } from 'utils';

import { DashboardList } from 'modules/Home/components/DashboardList';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePaths } from 'modules/root/Settings';

import { ActivityIcon, ActivitySection } from './ActivitySection';

interface EvaluationSectionProps {
  customerId: string;
  customerExternalId: string;
}

export const EvaluationSection: React.FC<EvaluationSectionProps> = ({
  customerId,
  customerExternalId,
}) => {
  const { labels } = useCustomisation();
  const { t } = useTranslation();
  const { data, isLoading, error } = useQueryFetch(useCustomerEvaluationsQuery, {
    queryHookParams: { customerID: customerId },
  });
  const paths = usePaths();
  const sendAudit = useSendAuditReport();
  const getHandlerEvaluationView =
    (evaluation: ArrayElement<CustomerEvaluationsQuery['customer']['evaluations']>) =>
    () => {
      sendAudit({
        activityType: ActivityAuditType.Read,
        customerExternalId,
        resourceAccessed: ResourceAccessed.CustomerActivityViewEvaluation,
        queryDetails: JSON.stringify({ evaluationId: evaluation?.id }),
      });
    };
  return (
    <ActivitySection label={labels.evaluations} items={data?.customer.evaluations || []}>
      {(items: CustomerEvaluationsQuery['customer']['evaluations']) => (
        <QuerySuspense
          error={error}
          isLoading={isLoading}
          noData={data?.customer.evaluations?.length === 0}
          noDataMessage={t('dashboard.activities.modal.noEvaluations')}
        >
          <DashboardList>
            {items?.map((evaluation) => (
              <DashboardList.Item key={evaluation?.id}>
                <div
                  className="flex justify-between"
                  data-testid="evaluation-section-item"
                >
                  <div className="flex items-center">
                    <ActivityIcon />
                    <span className="ml-[14px] mr-2">
                      {t('dashboard.activities.evaluation')}
                    </span>
                    <span>{`#${evaluation?.externalId}`}</span>
                  </div>
                  <ButtonLink
                    onClick={getHandlerEvaluationView(evaluation)}
                    data-testid="evaluation-btn-link"
                    state="secondary"
                    size="sm"
                    url={generatePath(paths.evaluations.view, {
                      customerId: evaluation?.customerId,
                      evaluationId: evaluation?.id,
                    })}
                  >
                    {t('dashboard.activities.view')}
                  </ButtonLink>
                </div>
              </DashboardList.Item>
            ))}
          </DashboardList>
        </QuerySuspense>
      )}
    </ActivitySection>
  );
};
