import React from 'react';

import Auth0Lock from 'auth0-lock';
import clsx from 'clsx';
import { LoadingSpinner } from 'components';
import { ActivityAuditType } from 'generated/graphql';
import { sendAuditReport } from 'services/auditReport';
import {
  informLogin,
  removeInformCookieFromServer,
  setInformCookieFromServer,
} from 'services/informToken';
import Cookies from 'universal-cookie';

import { AUTH0, INFORM_DOMAIN, INFORM_HOST } from 'modules/root/Settings/envVars';
import { AuthContextState, AuthProvider, Session } from 'modules/root/auth/auth.context';

export interface Auth0ProviderProps {
  children: React.ReactNode;
  clientId: string;
  domain: string;
  options: Auth0LockConstructorOptions;
}

const AUTH0_AUTH_NAME = 'nav_auth0_auth';
const AUTH0_TOKEN_NAME = 'nav_auth0_token';
const AUTH0_STATUS_NAME = 'nav_auth0_auth_status';
const ORIGINAL_PATH_NAME = 'nav_original_path';

enum AUTH0_STATUS {
  Authenticated = 'auth',
  Unauthenticated = 'unauth',
}

export const AUTH0_STORAGE_KEYS = {
  AUTH: AUTH0_AUTH_NAME,
  TOKEN: AUTH0_TOKEN_NAME,
  STATUS: AUTH0_STATUS_NAME, // is used to manage open tabs
};

const INFORM_TOKEN_COOKIE = 'inform_token';

export class Auth0Provider extends React.PureComponent<
  Auth0ProviderProps,
  AuthContextState
> {
  private lock: Auth0LockStatic;
  private tokenRenewalTimeout: null | NodeJS.Timeout = null;
  private navInformDomain = INFORM_DOMAIN;
  private isBackOfficeUser: boolean = false;

  static defaultProps = {
    options: {},
  };

  constructor(props: Auth0ProviderProps) {
    super(props);

    this.lock = new Auth0Lock(props.clientId, props.domain, props.options);
    this.state = {
      originPathKey: ORIGINAL_PATH_NAME,
      isAuthenticated: false,
      login: this.login,
      logout: this.logout,
      isLoading: true,
    };
  }

  componentDidMount(): void {
    this.lock.on('hash_parsed', async (authResult) => {
      if (authResult) {
        // the first login: clear cookies before any changes
        this.clearCookies();
        await this.handleAuthResult(authResult);
        await this.setInformCookie(
          authResult.idToken,
          authResult.idTokenPayload.exp * 1000
        );
        await informLogin(authResult);
        if (this.isBackOfficeUser) {
          this.redirectToInform();
        }
        sendAuditReport(authResult.idToken, { activityType: ActivityAuditType.Login });
      } else {
        this.restoreSession();
      }
    });

    this.lock.on('authorization_error', (error) => {
      console.error(error);
      this.setState({ isLoading: false });
    });

    window.addEventListener('storage', (e) => {
      if (e.key === AUTH0_STORAGE_KEYS.STATUS && e.newValue !== e.oldValue) {
        // if auth status for open tabs is changed
        const authStatus = e.newValue;
        if (authStatus === AUTH0_STATUS.Authenticated && !this.state.isAuthenticated) {
          setTimeout(() => window.location.reload(), 2000);
        } else if (
          authStatus === AUTH0_STATUS.Unauthenticated &&
          this.state.isAuthenticated
        ) {
          this.tabLogout();
        }
      }
    });
  }

  render(): React.ReactNode {
    const { children } = this.props;
    const { isLoading } = this.state;
    const isNotAuth =
      localStorage.getItem(AUTH0_STORAGE_KEYS.STATUS) !== AUTH0_STATUS.Authenticated;

    if (isLoading) {
      return (
        <div
          className={clsx('flex justify-center items-center h-full', {
            'bg-primary-500': isNotAuth,
          })}
        >
          <LoadingSpinner size="5x" />
        </div>
      );
    }

    return <AuthProvider value={this.state}>{children}</AuthProvider>;
  }

  componentWillUnmount(): void {
    this.clearIntervals();
    window.removeEventListener('store', () => undefined);
  }

  handleAuthResult = async (authResult: AuthResult): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
        if (error) {
          this.setState({
            isAuthenticated: false,
            isLoading: false,
          });
          reject(new Error(error.error));
        }
        if (
          AUTH0.TOKEN_CLAIM_NAMESPACE &&
          authResult.idTokenPayload[AUTH0.TOKEN_CLAIM_NAMESPACE]?.userGroups.includes(
            '__NAVIGATOR_BACK_OFFICE__'
          )
        ) {
          this.isBackOfficeUser = true;
        }
        this.setSession(authResult, profile);
        localStorage.setItem(AUTH0_STORAGE_KEYS.STATUS, AUTH0_STATUS.Authenticated);
        resolve();
      });
    });
  };

  redirectToInform = (): void => {
    if (INFORM_HOST) {
      window.location.href = INFORM_HOST;
    }
  };

  setSession = (authResult: AuthResult, profile: auth0.Auth0UserProfile): void => {
    const session: Session = {
      isAuthenticated: true,
      accessToken: authResult.accessToken,
      idToken: authResult.idToken,
      expiredAt: authResult.idTokenPayload.exp * 1000,
      profile,
    };

    this.setState(
      (prevState) => {
        // setting isLoading to isBackOfficeUser to not show navigator before redirecting to inform
        return {
          ...prevState,
          ...session,
          isLoading: this.isBackOfficeUser,
        };
      },
      () => {
        this.scheduleRenewal();
        this.storeSession(session);
      }
    );
  };

  setInformCookie = async (idToken: string, expiredAt: number): Promise<void> => {
    if (process.env.NODE_ENV !== 'production') {
      const cookies = new Cookies();
      cookies.set(INFORM_TOKEN_COOKIE, idToken, {
        path: '/',
        domain: this.navInformDomain,
        secure: true,
        expires: new Date(expiredAt),
      });
    } else {
      try {
        await setInformCookieFromServer(idToken, expiredAt);
      } catch (err) {
        console.error('Inform cookie error: ', err);
      }
    }
  };

  storeSession = (session: Session): void => {
    sessionStorage.setItem(AUTH0_STORAGE_KEYS.AUTH, JSON.stringify(session));
    sessionStorage.setItem(AUTH0_STORAGE_KEYS.TOKEN, session.idToken || '');
  };

  getStoredSession = (): Session | null => {
    const session = sessionStorage.getItem(AUTH0_STORAGE_KEYS.AUTH);
    return session ? JSON.parse(session) : null;
  };

  isSessionExpired = (expiredAt: number): boolean => {
    return new Date().getTime() > expiredAt;
  };

  restoreSession = (): void => {
    const session = this.getStoredSession();
    if (session && session.expiredAt && !this.isSessionExpired(session.expiredAt)) {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            ...session,
            isLoading: false,
          };
        },
        () => {
          this.scheduleRenewal();
        }
      );
    } else {
      this.lock.checkSession({}, (err, authResult) => {
        if (err) {
          console.error(err);
        }

        if (!authResult) {
          this.setState({
            isAuthenticated: false,
            isLoading: false,
          });
          return;
        }

        this.handleAuthResult(authResult);
        this.setInformCookie(authResult.idToken, authResult.idTokenPayload.exp * 1000);
      });
    }
  };

  login = (options?: { callbackFn?: Function }): void => {
    this.lock.show();
    const callbackFn = options?.callbackFn;
    if (callbackFn) {
      this.lock.on('show', () => {
        callbackFn();
      });
    }
  };

  tabLogout = () => {
    sessionStorage.clear();
    this.clearIntervals();
    this.lock.logout({ returnTo: window.location.origin });
  };

  logout = () => {
    if (this.state.idToken) {
      sendAuditReport(this.state.idToken, { activityType: ActivityAuditType.Logout });
    }
    sessionStorage.clear();
    this.clearIntervals();
    this.clearCookies();
    if (process.env.NODE_ENV === 'production') {
      removeInformCookieFromServer().catch((err) => {
        console.error('Inform cookie error: ', err.message);
      });
    }
    localStorage.setItem(AUTH0_STORAGE_KEYS.STATUS, AUTH0_STATUS.Unauthenticated);

    this.lock.logout({ returnTo: window.location.origin });
  };

  clearCookies = (): void => {
    const cookies = new Cookies();
    const all = cookies.getAll({ doNotParse: true });
    Object.keys(all).forEach((key) => {
      cookies.remove(key);
    });
  };

  clearIntervals = (): void => {
    if (this.tokenRenewalTimeout) {
      clearTimeout(this.tokenRenewalTimeout);
    }
  };

  scheduleRenewal = (): void => {
    const { expiredAt } = this.state;
    if (expiredAt) {
      const delay = expiredAt - Date.now();
      if (delay > 0) {
        this.tokenRenewalTimeout = setTimeout(() => {
          this.renewToken();
        }, delay);
      }
    }
  };

  renewToken = (): void => {
    this.lock.checkSession({}, (err, authResult) => {
      if (err || !authResult) {
        this.setState({ isAuthenticated: false });
      } else {
        this.setInformCookie(authResult.idToken, authResult.idTokenPayload.exp * 1000);
        this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
          this.setSession(authResult, profile);
        });
      }
    });
  };

  getToken = (): string => {
    return sessionStorage.getItem(AUTH0_STORAGE_KEYS.TOKEN) || '';
  };
}
