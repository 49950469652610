import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { FontIcon } from 'components';

export interface TextInputProps {
  name?: string;
  label?: string;
  text?: string;
  multiline?: boolean;
  maxLength?: number;
  errorMessage?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  prefixIcon?: IconProp;
  formatFn?: (value: string) => string;
  filterFn?: (value: string) => string;
  suffixIcon?: IconProp;
  suffixText?: string;
  tabIndex?: number;
  autocomplete?: 'on' | 'off';
  'data-testid'?: string;
  onChange?: (value: string) => void;
  onBlur?: (event: React.FocusEvent) => void;
}

export type Ref = HTMLInputElement;

const wrapperStyles = (valid: boolean, disabled: boolean) =>
  clsx(
    'group',
    'w-full',
    'flex',
    'items-center',
    'border',
    'rounded',
    'box-border',
    { 'border-gray-300': valid, 'border-red-500': !valid },
    { 'bg-gray-50': disabled, 'hover:bg-gray-50': !disabled },
    'px-4',
    'py-3',
    'outline-primary-600 outline-2',
    'focus-within:outline'
  );

const inputStyles = clsx(
  'w-full',
  'leading-4',
  'outline-none',
  'box-content',
  'text-base',
  'block',
  'bg-none',
  'border-0',
  'enabled:group-hover:bg-gray-50',
  'disabled:bg-gray-50',
  'disabled:text-gray-700'
);

const labelStyles = (valid: boolean, shrinkLabel: boolean, hasFocus: boolean) =>
  clsx(
    'block',
    'text-lg',
    'text-gray-500',
    'w-full',
    {
      'text-primary-600': hasFocus,
      'text-red-500': !valid,
    },
    'absolute',
    'origin-top-left',
    'left-4',
    'transition-all',
    shrinkLabel ? 'scale-75 translate-y-1 font-semibold' : 'scale-100 translate-y-4'
  );

const expandTextarea = (textarea: HTMLTextAreaElement) => {
  textarea.style.height = 'auto';
  textarea.style.height = textarea.scrollHeight + 'px';
};

export const TextInput: React.FC<TextInputProps> = ({
  name,
  label,
  text,
  maxLength,
  multiline,
  errorMessage,
  placeholder,
  className,
  disabled = false,
  prefixIcon,
  suffixIcon,
  suffixText,
  tabIndex,
  autocomplete = 'on',
  'data-testid': dataTestId,
  formatFn,
  filterFn,
  onChange,
  onBlur,
}) => {
  const inputRef = React.useRef(null);
  const inputId = `text-input${React.useId()}`;

  const handleClick = React.useCallback(() => {
    if (inputRef.current) {
      (inputRef.current as HTMLInputElement | HTMLTextAreaElement).focus();
    }
  }, []);

  const [value, setValue] = React.useState(text || '');
  const [shrinkLabel, setShrinkLabel] = React.useState(!!value || false);
  const [hasFocus, setHasFocus] = React.useState(false);

  React.useEffect(() => {
    setValue(text || '');
  }, [text]);

  React.useEffect(() => {
    hasFocus ? setShrinkLabel(true) : setShrinkLabel(!!value);
  }, [hasFocus, value, setShrinkLabel]);

  React.useLayoutEffect(() => {
    if (multiline && inputRef.current) {
      const textarea = inputRef.current as HTMLTextAreaElement;
      if (textarea.scrollHeight) {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
      }
    }
  }, [inputRef, multiline]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = event.target.value;
      if (filterFn) {
        value = filterFn(value);
      }
      setValue(value);
      if (onChange) {
        onChange(value);
      }
      if (multiline) {
        expandTextarea(event.target as HTMLTextAreaElement);
      }
    },
    [onChange, multiline, filterFn]
  );

  const handleBlur = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = event.target.value;
      if (formatFn) {
        value = formatFn(value);
      }
      setValue(value);
      if (!!value !== shrinkLabel) {
        setShrinkLabel(!!value);
      }

      if (onBlur) {
        onBlur(event);
      }
      if (multiline) {
        expandTextarea(event.target as HTMLTextAreaElement);
      }
      setHasFocus(false);
    },
    [onBlur, formatFn, multiline, shrinkLabel]
  );

  const handleFocus = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = event.target.value;
      if (filterFn) {
        value = filterFn(value);
        setValue(value);
      }
      setShrinkLabel(true);
      if (multiline) {
        expandTextarea(event.target as HTMLTextAreaElement);
      }
      setHasFocus(true);
    },
    [filterFn, multiline]
  );

  return (
    <div className={clsx('mt-1 mb-4 relative', className)} data-testid={dataTestId}>
      {label && (
        <label
          className={labelStyles(!errorMessage, shrinkLabel, hasFocus)}
          htmlFor={inputId}
        >
          {label}
        </label>
      )}
      <div className={wrapperStyles(!errorMessage, disabled)} onClick={handleClick}>
        {prefixIcon && <FontIcon className="h-4 w-4 mr-2 mt-4" icon={prefixIcon} />}
        {multiline ? (
          <textarea
            id={inputId}
            data-testid={`${dataTestId}-textarea`}
            name={name}
            value={value}
            placeholder={shrinkLabel ? placeholder : undefined}
            className={clsx(inputStyles, 'resize-none', 'overflow-y-auto', 'max-h-52', {
              'mt-4': label,
            })}
            rows={3}
            maxLength={maxLength}
            disabled={disabled}
            ref={inputRef}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            aria-invalid={errorMessage ? 'true' : 'false'}
            tabIndex={tabIndex}
            autoComplete={autocomplete}
          />
        ) : (
          <input
            id={inputId}
            data-testid={`${dataTestId}-input`}
            type="text"
            name={name}
            value={value}
            placeholder={placeholder}
            className={clsx(inputStyles, { 'mt-4': label })}
            maxLength={maxLength}
            disabled={disabled}
            ref={inputRef}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            aria-invalid={errorMessage ? 'true' : 'false'}
            tabIndex={tabIndex}
            autoComplete={autocomplete}
          ></input>
        )}
        {suffixIcon && <FontIcon className="h-4 w-4 ml-2" icon={suffixIcon} />}
        {suffixText && <span>{suffixText}</span>}
      </div>
      {!!errorMessage && (
        <div role="alert" className="text-red-500 mt-1 h-5 text-sm">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
