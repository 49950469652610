import { CompareTo, LoanStatus, RateType } from 'generated/graphql';
import { t } from 'i18next';

import { EMPTY_DASH } from './print';

export const getLoanRateTypeLabel = (rateType: RateType | null | undefined): string => {
  if (rateType === 'FIXED') {
    return t('customer.loans.interestPayments.rateTypeFixed');
  }
  if (rateType === 'VARIABLE') {
    return t('customer.loans.interestPayments.rateTypeVariable');
  }
  return EMPTY_DASH;
};

export const getStatusLabel = (status: LoanStatus | null | undefined): string => {
  if (!status) {
    return '';
  }

  const labels: Record<LoanStatus, string> = {
    ACTIVE: 'Active',
    CLOSED: 'Closed',
    DATA_ISSUE: 'Data Issue',
    OVER_ADVANCED: 'Over Advanced',
    PAID_OFF: 'Paid Off',
    SELL_OUT: 'Sell Out',
    TOP_UP: 'Top Up',
    WATCH: 'Watch',
    PROSPECT: 'Prospect',
  };
  return labels[status] || EMPTY_DASH;
};

export const getCompareToLabel = (compareTo: CompareTo | undefined | null) => {
  if (!compareTo) {
    return '';
  }
  const labels = {
    [CompareTo.MinimumBalance]: 'Minimum Balance',
    [CompareTo.OutstandingBalance]: 'Outstanding Balance',
    [CompareTo.CommitmentAmount]: 'Commitment Amount',
  };
  return labels[compareTo] || EMPTY_DASH;
};
