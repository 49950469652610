import React from 'react';

import clsx from 'clsx';
import { Badge, Ellipsis } from 'components';
import { AccountSource } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import userTickIcon from 'assets/icons/userTick.svg';

import { Checkbox, CheckboxOnChange } from 'components/Checkbox/Checkbox';
import { AccountItemState } from 'modules/EvaluationV2/models/account';
import { EvaluationAccount } from 'modules/EvaluationV2/types';
import { STATUS_BACKGROUND } from 'modules/common/enums/statusBackground';
import { useCustomisation } from 'modules/root/Settings';
import { printMoney } from 'utils/print';

interface AccountItemProps {
  account: EvaluationAccount;
  selected?: boolean;
  onAccountSelect?: (checked: boolean, value: string) => void;
  state?: AccountItemState;
}

export const AccountItem: React.FC<AccountItemProps> = ({
  account,
  selected = false,
  onAccountSelect,
  state = AccountItemState.selectable,
}) => {
  const { t } = useTranslation();
  const { labels } = useCustomisation();

  const handleAccountSelect: CheckboxOnChange = React.useCallback(
    ({ checked }) => {
      onAccountSelect && onAccountSelect(checked, account?.externalAccountId || '');
    },
    [account, onAccountSelect]
  );

  return (
    <div
      data-testid="evaluation-account-item"
      className={clsx('rounded-xl px-5 py-4 w-full border-gray-200 border', {
        'border-2 border-primary-600': selected,
        'cursor-pointer': state === AccountItemState.selectable,
        'hover:border-primary-200': !selected && state === AccountItemState.selectable,
        'focus-within:outline focus-within:outline-2 focus-within:shadow-md':
          state === AccountItemState.selectable,
        'bg-gray-50 text-gray-200': state === AccountItemState.unused,
      })}
      role="checkbox"
      aria-checked={selected}
      onClick={() => handleAccountSelect({ checked: !selected })}
    >
      <div className="flex items-center">
        <div className="grow">
          <div className="flex flex-col items-start gap-1">
            {account?.pledged && (
              <Badge size="sm" color={STATUS_BACKGROUND.YELLOW} label={labels.pledged} />
            )}
            <div className="font-bold max-w-[300px]" title={labels.collateralAccountId}>
              <Ellipsis>{account?.externalAccountId}</Ellipsis>
            </div>
            <div className="flex">
              <div className="max-w-[150px]" title={labels.owner}>
                <Ellipsis>
                  {account?.ownerDisplayName || t('evaluationV2.unavailable')}
                </Ellipsis>
              </div>
              <div className="px-2">{`|`}</div>
              <div className="max-w-[150px] grow" title={labels.custodian}>
                <Ellipsis>{account?.custodian || t('evaluationV2.unavailable')}</Ellipsis>
              </div>
            </div>
            {account?.source === AccountSource.Manual && (
              <div>
                <Badge
                  size="sm"
                  color={STATUS_BACKGROUND.GREEN}
                  label={t('evaluationV2.addedByUser').toUpperCase()}
                  iconSrc={userTickIcon}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end pl-10">
          <div className="flex flex-col items-end gap-1">
            <Badge
              size="sm"
              color={STATUS_BACKGROUND.PRIMARY}
              label={labels.marketValue}
            />
            <div className="font-bold tracking-wider">
              {account?.marketValue || account?.marketValue === 0
                ? printMoney(account?.marketValue)
                : t('evaluationV2.notPriced')}
            </div>
            <div title={labels.accountType}>
              {account?.accountType || t('evaluationV2.unavailable')}
            </div>
          </div>
        </div>
        {state === AccountItemState.selectable && (
          <div className="mx-5">
            <Checkbox
              data-testid={`check-${account?.id}`}
              name={`account-${account?.collateralAccountId}`}
              checked={selected}
              onChange={handleAccountSelect}
              disabled={false}
              ariaLabel={account?.externalAccountId}
            />
          </div>
        )}
      </div>
    </div>
  );
};
