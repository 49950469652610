import React from 'react';

import clsx from 'clsx';
import { HelpIcon } from 'components';
import { useAtomValue } from 'jotai';
import { printMoney } from 'utils';

import { ReactComponent as LegalSvg } from 'assets/icons/legal.svg';

import { evaluationAtom } from 'modules/EvaluationV2/models/evaluation';
import { useCustomisation } from 'modules/root/Settings';

interface FiguresProps {
  className?: string;
}

const baseClassName = 'flex justify-between border rounded-xl p-4';

export const Figures: React.FC<FiguresProps> = ({ className }) => {
  const evaluation = useAtomValue(evaluationAtom);
  const { labels, helpText, loanMarginAnalysis } = useCustomisation();
  return (
    <div
      className={clsx('flex flex-col gap-3', className)}
      data-testid="evaluation-figures"
    >
      {loanMarginAnalysis.showFields.maxCommitmentAmount && (
        <div
          className={clsx(baseClassName, 'border-primary-100 bg-primary-50 items-center')}
        >
          <div className="font-semibold flex gap-2 items-center">
            <HelpIcon text={helpText.maxCommitmentAmount} />
            <div className="rounded-md overflow-hidden w-8 h-8">
              <LegalSvg className="bg-primary-600/10 p-1 fill-primary-600 stroke-primary-300 w-full h-full" />
            </div>
            {labels.maxCommitmentAmount}
          </div>
          <div className="font-semibold text-xl tracking-wide">
            {printMoney(evaluation?.commitmentAmount)}
          </div>
        </div>
      )}
      <div className="flex justify-between gap-3">
        {loanMarginAnalysis.showFields.topUpThreshold && (
          <div className={clsx(baseClassName, 'basis-1/2 border-gray-200 items-center')}>
            <div className="flex gap-1 text-sm">
              <HelpIcon size="sm" text={helpText.topUpThreshold} />
              {labels.topUpThreshold}
            </div>
            <div className="font-semibold tracking-wide">
              {printMoney(evaluation?.topupThreshold)}
            </div>
          </div>
        )}
        {loanMarginAnalysis.showFields.sellOutThreshold && (
          <div className={clsx(baseClassName, 'basis-1/2 border-gray-200 items-center')}>
            <div className="flex gap-1 text-sm">
              <HelpIcon size="sm" text={helpText.sellOutThreshold} />
              {labels.sellOutThreshold}
            </div>
            <div className="font-semibold tracking-wide">
              {printMoney(evaluation?.selloutThreshold)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
