import React from 'react';

import { AppRouter, ErrorPage } from 'components';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'assets/style/global.css';
import 'assets/style/styles-nav.css';
import 'assets/style/styles-pnc.css';

import 'i18n/i18n';
import { RoutesComponent } from 'modules/root/Routes/Routes';
import { SettingsProvider } from 'modules/root/Settings';
import { Auth0Wrapper } from 'modules/root/auth/Auth0Wrapper/Auth0Wrapper';
import { LogoutOnIdle } from 'modules/root/auth/LogoutOnIdle/LogoutOnIdle';
import { history } from 'utils/history';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  },
});

const App: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={(error: FallbackProps) => <ErrorPage {...error} />}>
      <QueryClientProvider client={queryClient}>
        <SettingsProvider>
          <Auth0Wrapper>
            <LogoutOnIdle />
            <AppRouter basename={process.env.PUBLIC_URL} history={history}>
              <RoutesComponent />
            </AppRouter>
          </Auth0Wrapper>
        </SettingsProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
