import { LoanProposalQuery } from 'generated/graphql';
import { t } from 'i18next';
import { FieldErrors, Resolver } from 'react-hook-form';
import { getNumberValue, printMoney } from 'utils';

import { LoanProposalFormValues } from './types';

export const buildLoanProposalResolver = (
  config: LoanProposalQuery['applicationConfig'] | undefined
): Resolver<LoanProposalFormValues> => {
  return async (values) => {
    const errors: FieldErrors<LoanProposalFormValues> = {};
    const minLoanAmount = config?.loanProposal.minimumLoanAmount;
    const loanAmountValue = values?.loanAmount ? getNumberValue(values.loanAmount) : 0;
    if (minLoanAmount && loanAmountValue < minLoanAmount) {
      errors.loanAmount = {
        type: 'Minimum Loan Amount',
        message: t('loanProposal.minLoanAmoutError', {
          minLoanAmount: printMoney(minLoanAmount),
        }),
      };
    }
    return {
      values: Object.keys(errors).length ? {} : values,
      errors,
    };
  };
};
