import React from 'react';
import { useParams } from 'react-router';

import clsx from 'clsx';
import { LoadingSpinner } from 'components';
import {
  AccountListQuery,
  AccountSource,
  ActivityAuditType,
  useAccountListQuery,
} from 'generated/graphql';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmptyCards } from 'assets/icons/emptyCards.svg';

import {
  AccountItemState,
  accountsAtom,
  selAccountIdsAtom,
  systemAccountIdsAtom,
} from 'modules/EvaluationV2/models/account';
import { creditPolicyIdAtom } from 'modules/EvaluationV2/models/creditPolicy';
import {
  isCustomerEligibleAtom,
  selCustomerAtom,
} from 'modules/EvaluationV2/models/customer';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation } from 'modules/root/Settings';
import { DEFAULT_CREDITPOLICY } from 'modules/root/Settings/envVars';

import { AccountItem } from './AccountItem';
import { AddAccounts } from './AddAccounts';

interface AccountSelectorProps {
  withoutHeader?: boolean;
  className?: string;
}

export const AccountSelector: React.FC<AccountSelectorProps> = ({
  withoutHeader,
  className,
}) => {
  const [selAccountIds, setSelAccountIds] = useAtom(selAccountIdsAtom);
  const setSelCustomer = useSetAtom(selCustomerAtom);
  const [accounts, setAccounts] = useAtom(accountsAtom);
  const setSystemAccountIds = useSetAtom(systemAccountIdsAtom);
  const setCreditPolicyId = useSetAtom(creditPolicyIdAtom);
  const {
    creditPolicy: { allowCreditPolicySelection },
    evaluation,
  } = useCustomisation();
  const isCustomerEligible = useAtomValue(isCustomerEligibleAtom);

  const { customerId = '' } = useParams();
  const { t } = useTranslation();
  const { labels } = useCustomisation();
  const { isLoading, isError } = useQueryFetch(useAccountListQuery, {
    queryHookParams: { customerId },
    queryHookOptions: {
      enabled: !!customerId && !accounts,
      onSuccess: (data) => {
        const customer = (data as AccountListQuery).customer;
        setSelCustomer({
          id: customer.id,
          customerId: customer.customerId,
          displayName: customer.displayName,
          serviceStatus: customer.serviceStatus?.status,
        });
        if (!accounts || accounts?.length === 0) {
          const accountsToSet =
            customer.collateralAccounts?.reduce<NonNullable<typeof accounts>>(
              (_accounts, acc) => {
                if (acc) {
                  _accounts.push({
                    externalAccountId: acc.collateralAccountId || '',
                    source: AccountSource.System,
                    accountType: acc.accountType,
                    custodian: acc.custodian,
                    evaluationAssets: [],
                    id: acc.id,
                    marketValue: acc.marketData?.marketValue,
                    ownerId: acc.ownerId,
                    ownerDisplayName: customer.displayName,
                    pledged: acc.pledged,
                  });
                }
                return _accounts;
              },
              []
            ) || [];

          setAccounts(accountsToSet);
          setSystemAccountIds(accountsToSet.map((acc) => acc.externalAccountId));
          setSelAccountIds(new Set(accountsToSet.map((acc) => acc.externalAccountId)));
          if (DEFAULT_CREDITPOLICY && !allowCreditPolicySelection) {
            setCreditPolicyId(DEFAULT_CREDITPOLICY);
          }
        }
      },
      onError: (error) => {
        // error handling
      },
    },
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });

  const handleAccountSelection = React.useCallback(
    (checked: boolean, externalAccountId: string) => {
      if (checked && !selAccountIds.has(externalAccountId)) {
        selAccountIds.add(externalAccountId);
        setSelAccountIds(new Set(selAccountIds));
      } else {
        selAccountIds.delete(externalAccountId);
        setSelAccountIds(new Set(selAccountIds));
      }
    },
    [selAccountIds, setSelAccountIds]
  );

  return (
    <QuerySuspense
      error={isError}
      isLoading={isLoading}
      className={clsx('w-full', className)}
      loadingRenderer={() => <LoadingSpinner />}
    >
      {!isCustomerEligible && (
        <div className="alert" data-testid="not-eligible-alert">
          {t('evaluationV2.notEligible')}
        </div>
      )}
      {!isError && accounts?.length ? (
        <div className="flex flex-col w-full">
          {!withoutHeader && (
            <h2 className="font-bold text-lg mb-8">{labels.chooseAccounts}</h2>
          )}
          <div className="flex flex-col gap-4 w-full mb-8 p-2 overflow-y-auto">
            {accounts?.map(
              (account) =>
                account && (
                  <AccountItem
                    key={account.id}
                    account={account}
                    selected={
                      isCustomerEligible
                        ? selAccountIds.has(account.externalAccountId)
                        : undefined
                    }
                    onAccountSelect={
                      isCustomerEligible ? handleAccountSelection : undefined
                    }
                    state={
                      isCustomerEligible
                        ? AccountItemState.selectable
                        : AccountItemState.unused
                    }
                  />
                )
            )}
          </div>
          {evaluation.showButtons.addAccounts && (
            <div className="flex justify-between items-center p-2">
              <div>{labels.addAccountsInfo}</div>
              <AddAccounts state="secondary" disabled={!isCustomerEligible} />
            </div>
          )}
        </div>
      ) : (
        <div className="text-center">
          <h2 className="font-bold text-lg mb-8">{labels.chooseAccounts}</h2>
          <EmptyCards data-testid="empty-cards" className="mx-auto my-10 h-48" />
          {evaluation.showButtons.addAccounts && (
            <div className="flex flex-col gap-5">
              <div className="whitespace-pre-wrap">
                {t('evaluations.addAccountsEvaluation')}
              </div>
              <div className="flex justify-center">
                <AddAccounts state="primary" disabled={!isCustomerEligible} />
              </div>
            </div>
          )}
        </div>
      )}
    </QuerySuspense>
  );
};
